// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/leaves-top-left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/images/leaves-bottom-left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/images/leaves-top-right.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/images/leaves-bottom-right.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.logo-sign[data-v-00da8185] {\n  width: 64px;\n  height: 64px;\n  align-self: center;\n  margin-bottom: 32px;\n}\n.access-code-form-wrapper[data-v-00da8185] {\n  height: 100%;\n  width: 100vw !important;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-image:\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-position: top left, bottom left, top right, bottom right;\n  background-repeat: no-repeat;\n  background-size: 30%;\n@media (min-width: 768px) {\n    background-size: auto;\n}\n}\n.access-code-form[data-v-00da8185] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.error-messages[data-v-00da8185] {\n  width: 100%;\n  height: 56px;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  color: #DC2626;\n}\n.access-code-message[data-v-00da8185] {\n  font-family: \"Source Serif 4\", serif;\n  font-size: 1.25rem;\n  margin: 8px 0 16px;\n}\n.access-code-submit-button[data-v-00da8185] {\n  height: 52px !important;\n  width: -moz-fit-content !important;\n  width: fit-content !important;\n  background-color: #075985 !important;\n  color: #ffffff !important;\n  box-shadow: none !important;\n  border-radius: 16px !important;\n  font-size: 0.875rem;\n  padding: 0 24px !important;\n  box-sizing: border-box;\n  margin-top: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n@media (min-width: 480px) {\n    width: -moz-fit-content !important;\n    width: fit-content !important;\n}\n&[data-v-00da8185]:disabled {\n    background-color: #cccccc !important;\n    color: #ffffff !important;\n    cursor: not-allowed;\n}\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
